
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        























































































.body {
  margin-bottom: $text-xl !important;
  @media (max-width: $screen-width-sm) {
    width: 100vw;
  }
}

.hero {
  height: 480px;
  @media (max-width: $screen-width-sm) {
    display: none;
  }
}

.hero-image {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content-main {
  position: relative;
  padding: 0 $text-xl * 3;
  top: -200px;
  background-color: $white;
  margin-bottom: -200px;
  @media (max-width: $screen-width-sm) {
    top: 0;
    margin-bottom: 0;
    padding: $text-md;
  }
  .head {
    margin: $text-xs 0;
  }
}

.content-title {
  @include head-title;
  @media (max-width: $screen-width-sm) {
    font-size: $text-lg;
  }
}

.content-intro {
  @include head-body;
  @media (max-width: $screen-width-sm) {
    font-size: $text-sm;
  }
}

.content-body {
  @include regular-text;
  @media (max-width: $screen-width-sm) {
    font-size: $text-xs;
  }
}
