
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        
















































































































































































































































































.forwhom {
  &-header {
    text-align: center;
    margin-top: $text-md;
    margin-bottom: $text-lg;

    &-image {
      width: $screen-width-sm * 0.75;
      max-width: 100vw;
    }
    &-title {
      padding-top: $text-lg;
      @include head-title;
      @media (max-width: $screen-width-sm) {
        line-height: 1.2;
      }
    }
  }

  &-iconbar {
    padding: $text-xl 0;
    background-color: $gray-200;

    @media (max-width: $screen-width-md) {
      padding: $text-sm 0;
    }
  }

  &-squares {
    width: 100%;
    display: flex;
    justify-content: space-around;
    max-width: $screen-width-md;
    margin: auto;

    @media (max-width: $screen-width-md) {
      padding: 0 $text-xs;
      margin: 0;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &-details {
    background: linear-gradient(
      to bottom,
      $gray-200 70%,
      $accent-secondary-color 30%
    );
    &-description {
      background-color: white;
      max-width: $screen-width-lg;
      margin: auto;
      padding: $text-lg;
      &-title {
        color: $gray-700;
        font-size: $text-lg;
        text-align: center;
        padding-bottom: $text-md;
      }
      &-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media (max-width: $screen-width-sm) {
          display: block;
        }
      }
      &-body {
        padding-right: $text-lg;
        @media (max-width: $screen-width-sm) {
          width: 100%;
        }
      }
      &-list {
        @media (max-width: $screen-width-sm) {
          width: 100%;
        }
        .item {
          display: flex;
          padding-bottom: $text-md;
        }
        .number {
          color: $accent-color;
          width: 50px;
          min-width: 50px;
          max-width: 50px;
          height: 50px;
          line-height: 40px;
          padding-left: 6px;
          @include border-radius(50%);
          text-align: center;
          font-size: $text-md;
          border: 2px solid $accent-color;
          margin-right: 20px;
        }
        .content {
          &-title {
            font-weight: 900;
          }
          p {
            margin-bottom: 0;
          }
        }
      }
    }

    &-form {
      display: flex;
      justify-content: center;
      > div {
        flex: 0 1 400px;
      }
    }
    &-button {
      width: 100%;
      text-align: center;
      button {
        @include button;
        background-color: $accent-color;
        color: $white;
        border-color: $white;
        &:hover {
          background-color: $white;
          color: $accent-color;
          border-color: $accent-color;
        }
      }
    }
  }

  &-body {
    position: relative;
    background-color: $accent-secondary-color;
    margin: 0 auto;
    &-image {
      max-width: $screen-width-md;
      height: 840px;
      margin: auto;
      padding-top: 60px;
      padding-bottom: 140px;
      display: flex;
      img {
        height: 600px;
        margin: auto;
      }
    }

    &-icon {
      display: inline-block;
      width: 110px;
      position: absolute;
      text-align: center;
      a:hover {
        text-decoration: none;
      }
      &-button {
        margin: 0 -50px;
        display: inline;
        background-color: $accent-tertiary-color;
        color: white;
        text-align: center;
        font-size: $text-xs;
        font-weight: 600;
        padding: 2px $text-xs;
        white-space: nowrap;
      }
      &:hover {
        opacity: 0.5;
      }
    }

    &-icons {
      &-overlay {
        position: absolute;
        top: 0;
        height: 800px;
        width: 100%;

        @media (max-width: $screen-width-sm) {
          display: none;
        }
      }
      width: 600px;
      margin: auto;
      height: 100%;
      position: relative;

      img {
        width: 100%;
      }

      #company {
        top: 240px;
        left: 50px;
      }

      #event {
        top: 450px;
        left: 50px;
      }

      #one-on-one {
        top: 600px;
        left: 200px;
      }

      #trade-organisation {
        top: 450px;
        left: 440px;
      }

      #your-network {
        top: 240px;
        left: 440px;
      }
    }
  }
}
