
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        
















































































































































































.body {
  margin-bottom: $text-xl !important;
}

.hero {
  height: 480px;
  width: 100%;
  @media (max-width: $screen-width-sm) {
    height: 80vh;
    width: 100vw;
  }

  position: relative;
  margin-bottom: $text-xl;

  &-button {
    @include button;
    width: 260px;
    margin-right: $text-sm;

    &-dark {
      background-color: change-color($white, $alpha: 0) !important;
      border-color: $white !important;
      color: $white !important;
      &:hover {
        background-color: $white !important;
        color: $gray-700 !important;
        border-color: $gray-500 !important;
      }
    }

    &-light {
      background-color: $white !important;
      color: $gray-700 !important;
      border-color: $gray-500 !important;
      &:hover {
        background-color: change-color($white, $alpha: 0) !important;
        border-color: $white !important;
        color: $white !important;
      }
    }
  }
  &-body {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &-text {
    width: $screen-width-lg;
    margin: auto;
    text-align: left;

    padding: 120px 0px;
    @media (max-width: $screen-width-sm) {
      width: 100vw;
      padding: $text-lg $text-md;
      a {
        display: block;
        margin-bottom: $text-xs;
      }
    }
  }

  &-image {
    background-color: $gray-200;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.home-hero {
  line-height: 1;
  color: $white;
  @include header;
  @media (max-width: $screen-width-sm) {
    font-size: $text-lg;
  }
}

.home-lead {
  max-width: $screen-width-md;
  @include head-body;
  margin: auto;
  margin-bottom: $text-xl;
  @media (max-width: $screen-width-sm) {
    width: 80%;
  }
}

.home-intro {
  @include regular-text;
  font-size: $text-sm;
  text-align: left;
  color: $gray-700;
  width: 72%;
  margin: auto;
  margin-bottom: $text-xl;
  & code {
    color: blue;
  }
}

.persona {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: $screen-width-sm) {
    flex-direction: column;
  }
}

.home-kennisvrager,
.home-kennisgever {
  padding: $text-xl * 1.4 $text-lg;
  width: 48%;
  @media (max-width: $screen-width-sm) {
    width: 100vw;
    padding: $text-xl;
  }

  & .image {
    margin-top: -20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 240px;
    @media (max-width: $screen-width-sm) {
      width: 200px;
    }
  }

  &-titel {
    @include header;
    line-height: 1;
  }

  &-subtitel {
    @include regular-text;
    font-size: $text-md;
    line-height: 1.2;
  }

  &-link {
    font-size: $text-sm;
    font-weight: 300;
    line-height: 1.2;
    color: $white;
    display: flex;
    flex-direction: row;

    &-icon {
      width: $text-xl;
      margin-top: -12px;
      margin-right: -14px;
    }

    &:hover {
      color: $gray-400;
      text-decoration: none;
      &-body,
      &-icon {
        color: $gray-400;
        text-decoration: none;
      }
    }
  }
}

.home-kennisgever {
  text-align: left;
  background-color: $accent-color;
  color: $gray-200;

  & .image {
    color: $white;
    float: right;
    margin-right: -20px;
    @media (max-width: $screen-width-sm) {
      margin-left: -10px;
      margin-right: -60px;
    }
  }
}

.home-kennisvrager {
  text-align: right;
  background-color: $gray-200;
  color: $accent-color;

  & .image {
    color: $white;
    float: left;
    margin-left: -50px;
    margin-right: -40px;
  }
}

.home-kennisvrager-link {
  justify-content: flex-end;
  text-decoration: none;
  &:hover {
    color: $accent-color;
  }
}

.home-kennisgever-link {
  justify-content: flex-start;
  text-decoration: none;
  color: $white;
  &:hover {
    color: $gray-200;
  }
}

.home-mentor-carousel {
  width: $screen-width-lg;
  @media (max-width: $screen-width-sm) {
    width: 90vw;
    margin: auto;
  }

  &-header {
    padding: $text-lg * 1.4 0;
    @media (max-width: $screen-width-sm) {
      padding: $text-md 0 0 0;
      p {
        margin-bottom: 0;
      }
    }

    text-align: center;

    p {
      line-height: 1;
      color: $accent-color;
      font-size: $text-xl;
    }
  }
}
