
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        







































































.pilot {
  position: relative;

  &-header {
    width: 100%;
    height: 600px;
    margin-bottom: -200px;
    background-color: $accent-secondary-color;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
    display: block;
    @media (max-width: $screen-width-sm) {
      display: none;
    }

    &-mobile {
      display: none;
      @media (max-width: $screen-width-sm) {
        display: block;
        width: 100vw;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  &-body {
    position: relative;
    max-width: $screen-width-lg !important;
    padding: 0 $text-xl * 3;
    background-color: $white;
    margin: 0 auto;
    text-align: center;
    @media (max-width: $screen-width-sm) {
      max-width: 100vw;
      width: 100vw;
      padding: 0 $text-xs;
    }

    &-title {
      padding-top: $text-lg;
      @include head-title;
      @media (max-width: $screen-width-sm) {
        line-height: 1.2;
      }
    }

    &-intro {
      @include head-body;
      padding: $text-md $text-xl;
      @media (max-width: $screen-width-sm) {
        padding: $text-xs $text-xs;
        font-size: $text-sm;
        line-height: 1.4;
      }
    }
  }

  &-form {
    background-color: $gray-200;
    padding: $text-xl;

    &-columns {
      max-width: $screen-width-md;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: $text-xl;
      @media (max-width: $screen-width-sm) {
        display: block;
      }
    }
    &-title {
      color: $gray-700;
      font-size: $text-lg;
    }
    &-body {
      color: $gray-700;
      font-size: $text-sm;
      font-weight: 500;
    }
  }
}
