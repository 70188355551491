
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        










































.whats-app {
  padding: $text-md 0;
  background-color: $gray-200;

  @media (max-width: $screen-width-sm) {
    padding: 0;
    margin: 0;
  }

  &-content {
    display: flex;
    align-items: flex-end;
    max-width: $screen-width-sm;
    margin: auto;

    @media (max-width: $screen-width-sm) {
      width: 90vw;
      flex-wrap: wrap;
      .whats-app-title {
        display: block;
        padding: 1em 0.5em;
      }
      .whats-app-icon {
        display: none;
      }
      .whats-app-body {
        padding: 1em 0.5em;
      }
    }
  }

  &-title {
    color: $red;
    flex: 1 1 40%;
    font-size: 1.75em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    a {
      color: $red;
      font-weight: bold;
    }
  }
  &-icon {
    line-height: 1em;
    font-size: 5em;
    padding: 0 0.5em;

    a {
      color: $gray-900;
      font-weight: bold;
    }
  }
  &-body {
    color: $red;
    flex: 1 1 40%;
    font-size: 0.9em;
    line-height: 1.75em;
  }
}
