
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        






































.forwhom-square {
  width: 100%;
  color: $accent-color;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $screen-width-md) {
    display: inline;
    width: 33%;
  }
  @media (max-width: $screen-width-sm) {
    display: inline;
    width: 50%;
  }

  &-line {
    height: 1px;
    background-color: $accent-color;
    border: none;
    width: 80px;

    &-active {
      height: 2px;
    }
  }

  &-icon {
    position: relative;
    display: flex;
    justify-content: center;
    color: $accent-color;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
    &-active {
      width: 90px;
      height: 90px;
      padding-top: 0;
    }
  }

  a {
    color: $accent-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 $text-xs;
    &:hover {
      text-decoration: none;
    }
    @media (max-width: $screen-width-sm) {
      font-size: $text-xs;
    }
  }
  &:hover {
    opacity: 0.5;
  }

  &-active:hover {
    opacity: 1;
  }

  &-key {
    height: 48px;
    font-weight: bold;
    line-height: 1.3;
  }
}

.triangle-down {
  position: absolute;
  bottom: -5px;
  width: 0;
  height: 0;
  display: inline-block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid $white;
}
