
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        





































































































































































.howitworks {
  p {
    margin: 0;
  }

  &-header,
  &-footer {
    &-text {
      max-width: $screen-width-lg;
      text-align: left;

      @media (max-width: $screen-width-md) {
        a {
          display: block;
          margin-bottom: $text-xs;
        }
      }
    }

    &-button {
      @include button;
      width: 260px;
      margin-right: $text-sm;
      @media (max-width: $screen-width-sm) {
        margin: 0 auto;
      }

      &-dark {
        background-color: change-color($white, $alpha: 0) !important;
        border-color: $white !important;
        color: $white !important;
        &:hover {
          background-color: $white !important;
          color: $gray-700 !important;
          border-color: $gray-200 !important;
        }
      }

      &-light {
        background-color: $white !important;
        color: $gray-700 !important;
        border-color: $gray-200 !important;
        &:hover {
          background-color: change-color($white, $alpha: 0) !important;
          border-color: $white !important;
          color: $white !important;
        }
      }
    }
  }

  &-header {
    background-color: $gray-200;

    &-image-mobile {
      display: none;
      height: 500px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media (max-width: $screen-width-md) {
          object-fit: cover;
        }
      }
      @media (max-width: $screen-width-md) {
        display: block;
      }
    }

    &-text {
      max-width: $screen-width-sm;
      padding-top: 120px;
      @media (max-width: $screen-width-md) {
        padding-top: 0px;
      }
    }

    &-title {
      line-height: 1.2;
      color: $white;
      padding-bottom: $text-lg;
      @include header;
      @media (max-width: $screen-width-sm) {
        font-size: $text-lg;
        padding-bottom: $text-sm;
      }
    }

    &-body {
      min-height: 600px;
      margin: 0 auto;
      padding: $text-lg;
      max-width: $screen-width-lg;
      background-image: url("~@/assets/50minutes-hoewerkthet_header.jpg");
      background-repeat: no-repeat;
      background-position: right bottom;
      @media (max-width: $screen-width-md) {
        background-image: none;
        min-height: unset;
      }
    }
  }

  &-footer {
    background-color: $gray-400;
    width: 100%;

    &-text {
      padding: 60px 0;
      margin: auto;
      display: flex;
      justify-content: center;
      @media (max-width: $screen-width-sm) {
        display: block;
        padding: $text-lg $text-md $text-xs $text-md;
      }
    }
  }

  &-body {
    background-color: $gray-400;
    text-align: center;
    @media (max-width: $screen-width-md) {
      padding: 0 $text-xs;
      background-color: $gray-200;
    }

    &-header {
      text-align: center;
      background-color: $white;
      padding-top: $text-xl;
      padding-bottom: 100px;
    }

    &-image {
      width: 200px;
      height: 200px;
      margin: 0 auto;
      margin-top: -100px;

      img {
        background-color: $gray-200;
        @include border-radius(50%);
        border-width: $text-xs;
        border-color: $white;
        border-style: solid;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &-top,
    &-bottom {
      max-width: $screen-width-lg;
      margin: 0 auto;
    }

    &-top {
      background: linear-gradient(to bottom, $gray-200 90%, $accent-color 10%);
      margin-top: $text-lg;
    }

    &-bottom {
      background-color: $accent-color;
      &:before {
        content: "";
        background-color: $accent-color;
        height: 100px;
        position: relative;
        top: -100px;
      }
    }

    &-title {
      @include head-title;
      padding-bottom: $text-lg;
      @include header;
      @media (max-width: $screen-width-sm) {
        font-size: $text-lg;
        padding-bottom: $text-sm;
      }
    }

    &-intro {
      @include head-body;
      max-width: $screen-width-sm;
      @media (max-width: $screen-width-md) {
        width: 100%;
        padding: 0 $text-sm;
      }
      margin: 0 auto;
      padding-bottom: $text-lg;
      @media (max-width: $screen-width-sm) {
        font-size: $text-sm;
      }
    }

    &-step {
      &-image {
        img {
          height: 280px;
          @media (max-width: $screen-width-sm) {
            height: auto;
            width: 100%;
          }
        }
      }
      &-text {
        background-color: $white;
        margin: 0 auto;
        width: $screen-width-md;
        @media (max-width: $screen-width-md) {
          width: 100%;
        }
        font-size: $text-md;
        font-weight: 600;
      }

      &-title {
        background-color: $accent-tertiary-color;
        color: $white;
        text-transform: uppercase;
        width: 120px;
        margin: 0 auto;
        padding: $text-xs/2;
        font-size: $text-sm;
        margin-bottom: $text-md;
        line-height: 1.2;
      }

      &-body {
        line-height: 1.2;
        @media (max-width: $screen-width-sm) {
          padding-left: $text-xs;
          padding-right: $text-xs;
        }
        padding-bottom: $text-xl;
      }

      &-column {
        text-align: left;
        font-weight: 400;
        padding-bottom: $text-xl;
        @media (max-width: $screen-width-md) {
          padding-bottom: $text-sm;
        }
        &-title {
          color: $accent-color;
          font-weight: 500;
          padding-left: $text-sm + $text-xs * 1.5;
          line-height: 1.2;
          padding-bottom: $text-xs;
          @media (max-width: $screen-width-md) {
            text-align: center;
            padding-left: 0px;
          }
        }

        &-list {
          @media (max-width: $screen-width-md) {
            padding-right: $text-xs;
          }
          ul {
            list-style-type: none;
            padding-left: $text-sm;
          }

          li {
            font-size: $text-sm;
            font-weight: 500;
          }

          li:before {
            content: "";
            display: inline-block;
            height: $text-xs;
            width: $text-xs;
            @include border-radius(50%);
            background-image: url("/img/icons/meerzien.svg");
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: $text-xs/2;
            svg {
              color: white;
            }
          }
        }
      }
    }

    &-step2 {
      &-image {
        margin-top: -30px;
      }

      &-grid {
        max-width: $screen-width-md;
        margin: 0 auto;
        display: grid;
        grid-template:
          "c1 c1 c2 c2 c3 c3"
          "c4 c4 c4 c5 c5 c5"
          / 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-gap: $text-lg;
        @media (max-width: $screen-width-md) {
          grid-template: "c2" "c1" "c4" "c3" "c5" / 1fr;
          grid-gap: $text-xs;
        }
        &-image {
          width: 160px;
          height: 160px;
          margin-top: -20px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        &-title {
          line-height: 1.2;
          @media (max-width: $screen-width-sm) {
            padding-left: $text-xs;
            padding-right: $text-xs;
          }
        }
      }
    }

    &-step3 {
      padding-bottom: $text-lg;
      &-logo {
        color: $white;
        width: $screen-width-sm;
        @media (max-width: $screen-width-sm) {
          width: 90%;
        }
      }
      &-text {
        color: $white;
        font-size: $text-md;
        width: $screen-width-sm;
        @media (max-width: $screen-width-sm) {
          width: 100%;
          padding: 0 $text-xs;
        }
        margin: 0 auto;
      }
    }
  }
}

.image-left {
  margin-left: 60px;
  @media (max-width: $screen-width-md) {
    margin: 0 auto;
  }
}

.image-right {
  margin-left: 0px;
  @media (max-width: $screen-width-md) {
    margin: 0 auto;
  }
}
