@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.mentor-card[data-v-7cb62c3d] {
  text-align: left;
  height: 100%;
  width: 100%;
}
.mentor-card[data-v-7cb62c3d]:hover {
    opacity: 0.5;
    text-decoration: none;
}
.mentor-card:hover *[data-v-7cb62c3d] {
      text-decoration: none;
}
.mentor-header[data-v-7cb62c3d] {
  height: 50%;
}
.mentor-image[data-v-7cb62c3d] {
  height: 100%;
  width: 100%;
}
.mentor-image img[data-v-7cb62c3d] {
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center top;
       object-position: center top;
    width: 100%;
    height: 100%;
    background-color: #aca79e;
}
.mentor-body[data-v-7cb62c3d] {
  height: 50%;
  padding: 14px 19px;
  background-color: #edeae5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.mentor-name[data-v-7cb62c3d] {
  font-weight: 700;
  font-size: 26px;
  color: #f3594c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  padding: 2px 0 2px 0;
}
.mentor-title[data-v-7cb62c3d] {
  line-height: 1.2;
  color: #aca79e;
  padding-bottom: 19px;
  font-weight: 400;
  font-size: 19px;
}
.mentor-icons[data-v-7cb62c3d] {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  color: #f3594c;
  padding: 0 14px;
}
.mentor-icons *[data-v-7cb62c3d] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.mentor-icons a[data-v-7cb62c3d] {
    color: #f3594c;
}
.mentor-icons a[data-v-7cb62c3d]:hover {
      text-decoration: none;
      color: #888894;
}
.mentor-icons a:hover .icon[data-v-7cb62c3d] {
        color: #f3594c;
        background-color: #d8d6cd;
}
.mentor-icons .mentor-icon-image[data-v-7cb62c3d] {
    border-radius: 50%;
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
    background-color: #fff;
    width: 36px;
    height: 36px;
    margin-bottom: 4px;
}
.mentor-icons .mentor-icon-image .phone[data-v-7cb62c3d] {
      padding: 4px 0 6px 0;
}
.mentor-icons .mentor-icon-value[data-v-7cb62c3d], .mentor-icons .mentor-icon-label[data-v-7cb62c3d] {
    font-size: 14px;
    line-height: 1.3;
}
.mentor-icons .mentor-icon-value[data-v-7cb62c3d] {
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.mentor-carousel[data-v-e8801968] {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(31%, 1fr));
  grid-gap: 19px;
}
@media (max-width: 576px) {
.mentor-carousel[data-v-e8801968] {
      display: block;
}
.mentor-carousel-card[data-v-e8801968] {
        max-width: 80vw;
        width: 80vw;
        height: 80vh;
        margin: 10vw;
}
}
.mentor-carousel-card[data-v-e8801968] {
    height: 480px;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.body {
  margin-bottom: 44px !important;
}
.hero {
  height: 480px;
  width: 100%;
  position: relative;
  margin-bottom: 44px;
}
@media (max-width: 576px) {
.hero {
      height: 80vh;
      width: 100vw;
}
}
.hero-button {
    display: inline-block;
    font-size: 19px;
    line-height: 1.2;
    font-weight: inherit;
    color: #000;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 0;
    padding: 10px 44px 12px 44px;
    width: 260px;
    margin-right: 19px;
}
.hero-button:hover {
      color: #000;
      text-decoration: none;
}
.hero-button:focus, .hero-button.focus {
      outline: 0;
      color: #f3594c;
      background-color: #fff;
      border-color: #fff;
}
.hero-button-dark {
      background-color: rgba(255, 255, 255, 0) !important;
      border-color: #fff !important;
      color: #fff !important;
}
.hero-button-dark:hover {
        background-color: #fff !important;
        color: #414042 !important;
        border-color: #aca79e !important;
}
.hero-button-light {
      background-color: #fff !important;
      color: #414042 !important;
      border-color: #aca79e !important;
}
.hero-button-light:hover {
        background-color: rgba(255, 255, 255, 0) !important;
        border-color: #fff !important;
        color: #fff !important;
}
.hero-body {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.hero-text {
    width: 992px;
    margin: auto;
    text-align: left;
    padding: 120px 0px;
}
@media (max-width: 576px) {
.hero-text {
        width: 100vw;
        padding: 36px 26px;
}
.hero-text a {
          display: block;
          margin-bottom: 14px;
}
}
.hero-image {
    background-color: #d8d6cd;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.hero-image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
}
.home-hero {
  line-height: 1;
  color: #fff;
  font-weight: 700;
  font-size: 44px;
}
@media (max-width: 576px) {
.home-hero {
      font-size: 36px;
}
}
.home-lead {
  max-width: 768px;
  margin-top: 26px;
  font-weight: 300;
  font-size: 26px;
  color: #414042;
  line-height: 1.3;
  margin: auto;
  margin-bottom: 44px;
}
@media (max-width: 576px) {
.home-lead {
      width: 80%;
}
}
.home-intro {
  font-weight: 400;
  font-size: 19px;
  font-size: 19px;
  text-align: left;
  color: #414042;
  width: 72%;
  margin: auto;
  margin-bottom: 44px;
}
.home-intro code {
    color: blue;
}
.persona {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
@media (max-width: 576px) {
.persona {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
}
}
.home-kennisvrager,
.home-kennisgever {
  padding: 61.6px 36px;
  width: 48%;
}
@media (max-width: 576px) {
.home-kennisvrager,
    .home-kennisgever {
      width: 100vw;
      padding: 44px;
}
}
.home-kennisvrager .image,
  .home-kennisgever .image {
    margin-top: -20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 240px;
}
@media (max-width: 576px) {
.home-kennisvrager .image,
      .home-kennisgever .image {
        width: 200px;
}
}
.home-kennisvrager-titel,
  .home-kennisgever-titel {
    font-weight: 700;
    font-size: 44px;
    line-height: 1;
}
.home-kennisvrager-subtitel,
  .home-kennisgever-subtitel {
    font-weight: 400;
    font-size: 19px;
    font-size: 26px;
    line-height: 1.2;
}
.home-kennisvrager-link,
  .home-kennisgever-link {
    font-size: 19px;
    font-weight: 300;
    line-height: 1.2;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.home-kennisvrager-link-icon,
    .home-kennisgever-link-icon {
      width: 44px;
      margin-top: -12px;
      margin-right: -14px;
}
.home-kennisvrager-link:hover,
    .home-kennisgever-link:hover {
      color: #c8c9ba;
      text-decoration: none;
}
.home-kennisvrager-link:hover-body, .home-kennisvrager-link:hover-icon,
      .home-kennisgever-link:hover-body,
      .home-kennisgever-link:hover-icon {
        color: #c8c9ba;
        text-decoration: none;
}
.home-kennisgever {
  text-align: left;
  background-color: #f3594c;
  color: #d8d6cd;
}
.home-kennisgever .image {
    color: #fff;
    float: right;
    margin-right: -20px;
}
@media (max-width: 576px) {
.home-kennisgever .image {
        margin-left: -10px;
        margin-right: -60px;
}
}
.home-kennisvrager {
  text-align: right;
  background-color: #d8d6cd;
  color: #f3594c;
}
.home-kennisvrager .image {
    color: #fff;
    float: left;
    margin-left: -50px;
    margin-right: -40px;
}
.home-kennisvrager-link {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  text-decoration: none;
}
.home-kennisvrager-link:hover {
    color: #f3594c;
}
.home-kennisgever-link {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  text-decoration: none;
  color: #fff;
}
.home-kennisgever-link:hover {
    color: #d8d6cd;
}
.home-mentor-carousel {
  width: 992px;
}
@media (max-width: 576px) {
.home-mentor-carousel {
      width: 90vw;
      margin: auto;
}
}
.home-mentor-carousel-header {
    padding: 50.4px 0;
    text-align: center;
}
@media (max-width: 576px) {
.home-mentor-carousel-header {
        padding: 26px 0 0 0;
}
.home-mentor-carousel-header p {
          margin-bottom: 0;
}
}
.home-mentor-carousel-header p {
      line-height: 1;
      color: #f3594c;
      font-size: 44px;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.howitworks p {
  margin: 0;
}
.howitworks-header-text, .howitworks-footer-text {
  max-width: 992px;
  text-align: left;
}
@media (max-width: 768px) {
.howitworks-header-text a, .howitworks-footer-text a {
      display: block;
      margin-bottom: 14px;
}
}
.howitworks-header-button, .howitworks-footer-button {
  display: inline-block;
  font-size: 19px;
  line-height: 1.2;
  font-weight: inherit;
  color: #000;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 0;
  padding: 10px 44px 12px 44px;
  width: 260px;
  margin-right: 19px;
}
.howitworks-header-button:hover, .howitworks-footer-button:hover {
    color: #000;
    text-decoration: none;
}
.howitworks-header-button:focus, .howitworks-header-button.focus, .howitworks-footer-button:focus, .howitworks-footer-button.focus {
    outline: 0;
    color: #f3594c;
    background-color: #fff;
    border-color: #fff;
}
@media (max-width: 576px) {
.howitworks-header-button, .howitworks-footer-button {
      margin: 0 auto;
}
}
.howitworks-header-button-dark, .howitworks-footer-button-dark {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: #fff !important;
    color: #fff !important;
}
.howitworks-header-button-dark:hover, .howitworks-footer-button-dark:hover {
      background-color: #fff !important;
      color: #414042 !important;
      border-color: #d8d6cd !important;
}
.howitworks-header-button-light, .howitworks-footer-button-light {
    background-color: #fff !important;
    color: #414042 !important;
    border-color: #d8d6cd !important;
}
.howitworks-header-button-light:hover, .howitworks-footer-button-light:hover {
      background-color: rgba(255, 255, 255, 0) !important;
      border-color: #fff !important;
      color: #fff !important;
}
.howitworks-header {
  background-color: #d8d6cd;
}
.howitworks-header-image-mobile {
    display: none;
    height: 500px;
}
.howitworks-header-image-mobile img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
}
@media (max-width: 768px) {
.howitworks-header-image-mobile img {
          -o-object-fit: cover;
             object-fit: cover;
}
}
@media (max-width: 768px) {
.howitworks-header-image-mobile {
        display: block;
}
}
.howitworks-header-text {
    max-width: 576px;
    padding-top: 120px;
}
@media (max-width: 768px) {
.howitworks-header-text {
        padding-top: 0px;
}
}
.howitworks-header-title {
    line-height: 1.2;
    color: #fff;
    padding-bottom: 36px;
    font-weight: 700;
    font-size: 44px;
}
@media (max-width: 576px) {
.howitworks-header-title {
        font-size: 36px;
        padding-bottom: 19px;
}
}
.howitworks-header-body {
    min-height: 600px;
    margin: 0 auto;
    padding: 36px;
    max-width: 992px;
    background-image: url(../img/50minutes-hoewerkthet_header.2e9d3db7.jpg);
    background-repeat: no-repeat;
    background-position: right bottom;
}
@media (max-width: 768px) {
.howitworks-header-body {
        background-image: none;
        min-height: unset;
}
}
.howitworks-footer {
  background-color: #c8c9ba;
  width: 100%;
}
.howitworks-footer-text {
    padding: 60px 0;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
@media (max-width: 576px) {
.howitworks-footer-text {
        display: block;
        padding: 36px 26px 14px 26px;
}
}
.howitworks-body {
  background-color: #c8c9ba;
  text-align: center;
}
@media (max-width: 768px) {
.howitworks-body {
      padding: 0 14px;
      background-color: #d8d6cd;
}
}
.howitworks-body-header {
    text-align: center;
    background-color: #fff;
    padding-top: 44px;
    padding-bottom: 100px;
}
.howitworks-body-image {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    margin-top: -100px;
}
.howitworks-body-image img {
      background-color: #d8d6cd;
      border-radius: 50%;
      background-clip: padding-box;
      /* stops bg color from leaking outside the border: */
      border-width: 14px;
      border-color: #fff;
      border-style: solid;
      height: 100%;
      width: 100%;
      -o-object-fit: cover;
         object-fit: cover;
}
.howitworks-body-top, .howitworks-body-bottom {
    max-width: 992px;
    margin: 0 auto;
}
.howitworks-body-top {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(90%, #d8d6cd), color-stop(10%, #f3594c));
    background: linear-gradient(to bottom, #d8d6cd 90%, #f3594c 10%);
    margin-top: 36px;
}
.howitworks-body-bottom {
    background-color: #f3594c;
}
.howitworks-body-bottom:before {
      content: "";
      background-color: #f3594c;
      height: 100px;
      position: relative;
      top: -100px;
}
.howitworks-body-title {
    line-height: 1;
    color: #f3594c;
    font-size: 44px;
    font-weight: 700;
    padding-bottom: 36px;
    font-weight: 700;
    font-size: 44px;
}
@media (max-width: 576px) {
.howitworks-body-title {
        font-size: 36px;
        padding-bottom: 19px;
}
}
.howitworks-body-intro {
    margin-top: 26px;
    font-weight: 300;
    font-size: 26px;
    color: #414042;
    line-height: 1.3;
    max-width: 576px;
    margin: 0 auto;
    padding-bottom: 36px;
}
@media (max-width: 768px) {
.howitworks-body-intro {
        width: 100%;
        padding: 0 19px;
}
}
@media (max-width: 576px) {
.howitworks-body-intro {
        font-size: 19px;
}
}
.howitworks-body-step-image img {
    height: 280px;
}
@media (max-width: 576px) {
.howitworks-body-step-image img {
        height: auto;
        width: 100%;
}
}
.howitworks-body-step-text {
    background-color: #fff;
    margin: 0 auto;
    width: 768px;
    font-size: 26px;
    font-weight: 600;
}
@media (max-width: 768px) {
.howitworks-body-step-text {
        width: 100%;
}
}
.howitworks-body-step-title {
    background-color: #f4bf29;
    color: #fff;
    text-transform: uppercase;
    width: 120px;
    margin: 0 auto;
    padding: 7px;
    font-size: 19px;
    margin-bottom: 26px;
    line-height: 1.2;
}
.howitworks-body-step-body {
    line-height: 1.2;
    padding-bottom: 44px;
}
@media (max-width: 576px) {
.howitworks-body-step-body {
        padding-left: 14px;
        padding-right: 14px;
}
}
.howitworks-body-step-column {
    text-align: left;
    font-weight: 400;
    padding-bottom: 44px;
}
@media (max-width: 768px) {
.howitworks-body-step-column {
        padding-bottom: 19px;
}
}
.howitworks-body-step-column-title {
      color: #f3594c;
      font-weight: 500;
      padding-left: 40px;
      line-height: 1.2;
      padding-bottom: 14px;
}
@media (max-width: 768px) {
.howitworks-body-step-column-title {
          text-align: center;
          padding-left: 0px;
}
}
@media (max-width: 768px) {
.howitworks-body-step-column-list {
        padding-right: 14px;
}
}
.howitworks-body-step-column-list ul {
      list-style-type: none;
      padding-left: 19px;
}
.howitworks-body-step-column-list li {
      font-size: 19px;
      font-weight: 500;
}
.howitworks-body-step-column-list li:before {
      content: "";
      display: inline-block;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background-clip: padding-box;
      /* stops bg color from leaking outside the border: */
      background-image: url("/img/icons/meerzien.svg");
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 7px;
}
.howitworks-body-step-column-list li:before svg {
        color: white;
}
.howitworks-body-step2-image {
    margin-top: -30px;
}
.howitworks-body-step2-grid {
    max-width: 768px;
    margin: 0 auto;
    display: grid;
    grid-template: "c1 c1 c2 c2 c3 c3" "c4 c4 c4 c5 c5 c5" / 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 36px;
}
@media (max-width: 768px) {
.howitworks-body-step2-grid {
        grid-template: "c2" "c1" "c4" "c3" "c5" / 1fr;
        grid-gap: 14px;
}
}
.howitworks-body-step2-grid-image {
      width: 160px;
      height: 160px;
      margin-top: -20px;
}
.howitworks-body-step2-grid-image img {
        width: 100%;
        height: 100%;
}
.howitworks-body-step2-grid-title {
      line-height: 1.2;
}
@media (max-width: 576px) {
.howitworks-body-step2-grid-title {
          padding-left: 14px;
          padding-right: 14px;
}
}
.howitworks-body-step3 {
    padding-bottom: 36px;
}
.howitworks-body-step3-logo {
      color: #fff;
      width: 576px;
}
@media (max-width: 576px) {
.howitworks-body-step3-logo {
          width: 90%;
}
}
.howitworks-body-step3-text {
      color: #fff;
      font-size: 26px;
      width: 576px;
      margin: 0 auto;
}
@media (max-width: 576px) {
.howitworks-body-step3-text {
          width: 100%;
          padding: 0 14px;
}
}
.image-left {
  margin-left: 60px;
}
@media (max-width: 768px) {
.image-left {
      margin: 0 auto;
}
}
.image-right {
  margin-left: 0px;
}
@media (max-width: 768px) {
.image-right {
      margin: 0 auto;
}
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
button[data-v-ebd11b30] {
  display: inline-block;
  font-size: 19px;
  line-height: 1.2;
  font-weight: inherit;
  color: #000;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 0;
  padding: 10px 44px 12px 44px;
  border: 1px solid #f3594c;
  text-decoration: none;
  background: #f3594c;
  color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
}
button[data-v-ebd11b30]:hover {
    color: #000;
    text-decoration: none;
}
button[data-v-ebd11b30]:focus, button.focus[data-v-ebd11b30] {
    outline: 0;
    color: #f3594c;
    background-color: #fff;
    border-color: #fff;
}
@media (max-width: 576px) {
button[data-v-ebd11b30] {
      font-size: 14px;
      width: 100%;
      text-align: center;
      padding: 10px 14px 12px 14px;
}
}
button[data-v-ebd11b30]:disabled, button[data-v-ebd11b30]:disabled:hover {
    color: #f3594c;
    border-color: #f3594c;
    background: #fff;
    cursor: not-allowed;
}
button[data-v-ebd11b30]:hover, button[data-v-ebd11b30]:focus {
    background: #fff;
    color: #f3594c;
    border-color: #f3594c;
}
.section[data-v-ebd11b30] {
  margin-top: 20px;
}
.section h3[data-v-ebd11b30] {
    margin-bottom: 10px;
}
.section label[data-v-ebd11b30] {
    display: none;
    width: 260px;
    text-align: right;
    margin-right: 10px;
}
@media (max-width: 576px) {
.section label[data-v-ebd11b30] {
        width: auto;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 2px;
}
}
.section textarea[data-v-ebd11b30],
  .section input[data-v-ebd11b30] {
    font-size: 19px;
    padding: 0px 10px;
    border: 1px solid #d8d6cd;
}
@media (max-width: 576px) {
.section textarea[data-v-ebd11b30],
      .section input[data-v-ebd11b30] {
        font-size: 14px;
}
}
.section textarea[data-v-ebd11b30]:focus,
    .section input[data-v-ebd11b30]:focus {
      outline: none;
}
.section input[data-v-ebd11b30] {
    width: 100%;
    min-height: 50px;
}
.section input[data-v-ebd11b30]::-webkit-input-placeholder {
      color: #aca79e;
}
.section input[data-v-ebd11b30]::-moz-placeholder {
      color: #aca79e;
}
.section input[data-v-ebd11b30]:-ms-input-placeholder {
      color: #aca79e;
}
.section input[data-v-ebd11b30]::-ms-input-placeholder {
      color: #aca79e;
}
.section input[data-v-ebd11b30]::placeholder {
      color: #aca79e;
}
.button-row[data-v-ebd11b30] {
  margin-top: 26px;
}
.invalid[data-v-ebd11b30] {
  outline: 1px solid #f3594c;
}
.question[data-v-ebd11b30] {
  margin-top: 16px;
  width: 100%;
  height: 200px;
  resize: none;
  border-color: #edeae5;
}
.error[data-v-ebd11b30] {
  color: #f3594c;
}
.hidden[data-v-ebd11b30] {
  display: none !important;
}
.subscribeNewsletter[data-v-ebd11b30] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 14px;
  padding-left: 4px;
  margin-bottom: 19px;
}
.subscribeNewsletter input[data-v-ebd11b30] {
    width: auto;
    margin-bottom: 0;
}
label[for="subscribeNewsletter"][data-v-ebd11b30] {
  display: block;
  line-height: 1;
  padding: 0;
  padding-left: 26px;
  margin-bottom: 0;
}
.registration-confirm-title[data-v-ebd11b30] {
  color: #414042;
  font-size: 36px;
}
.registration-confirm-body[data-v-ebd11b30] {
  color: #414042;
  font-size: 19px;
  font-weight: 500;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.forwhom-square {
  width: 100%;
  color: #f3594c;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 768px) {
.forwhom-square {
      display: inline;
      width: 33%;
}
}
@media (max-width: 576px) {
.forwhom-square {
      display: inline;
      width: 50%;
}
}
.forwhom-square-line {
    height: 1px;
    background-color: #f3594c;
    border: none;
    width: 80px;
}
.forwhom-square-line-active {
      height: 2px;
}
.forwhom-square-icon {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: #f3594c;
    width: 80px;
    height: 80px;
    margin: 0 auto;
}
.forwhom-square-icon img {
      display: block;
      width: 100%;
      height: 100%;
}
.forwhom-square-icon-active {
      width: 90px;
      height: 90px;
      padding-top: 0;
}
.forwhom-square a {
    color: #f3594c;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 14px;
}
.forwhom-square a:hover {
      text-decoration: none;
}
@media (max-width: 576px) {
.forwhom-square a {
        font-size: 14px;
}
}
.forwhom-square:hover {
    opacity: 0.5;
}
.forwhom-square-active:hover {
    opacity: 1;
}
.forwhom-square-key {
    height: 48px;
    font-weight: bold;
    line-height: 1.3;
}
.triangle-down {
  position: absolute;
  bottom: -5px;
  width: 0;
  height: 0;
  display: inline-block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.forwhom-header[data-v-9ee22376] {
  text-align: center;
  margin-top: 26px;
  margin-bottom: 36px;
}
.forwhom-header-image[data-v-9ee22376] {
    width: 432px;
    max-width: 100vw;
}
.forwhom-header-title[data-v-9ee22376] {
    padding-top: 36px;
    line-height: 1;
    color: #f3594c;
    font-size: 44px;
    font-weight: 700;
}
@media (max-width: 576px) {
.forwhom-header-title[data-v-9ee22376] {
        line-height: 1.2;
}
}
.forwhom-iconbar[data-v-9ee22376] {
  padding: 44px 0;
  background-color: #d8d6cd;
}
@media (max-width: 768px) {
.forwhom-iconbar[data-v-9ee22376] {
      padding: 19px 0;
}
}
.forwhom-squares[data-v-9ee22376] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  max-width: 768px;
  margin: auto;
}
@media (max-width: 768px) {
.forwhom-squares[data-v-9ee22376] {
      padding: 0 14px;
      margin: 0;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
}
}
.forwhom-details[data-v-9ee22376] {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(70%, #d8d6cd), color-stop(30%, #495161));
  background: linear-gradient(to bottom, #d8d6cd 70%, #495161 30%);
}
.forwhom-details-description[data-v-9ee22376] {
    background-color: white;
    max-width: 992px;
    margin: auto;
    padding: 36px;
}
.forwhom-details-description-title[data-v-9ee22376] {
      color: #414042;
      font-size: 36px;
      text-align: center;
      padding-bottom: 26px;
}
.forwhom-details-description-content[data-v-9ee22376] {
      display: grid;
      grid-template-columns: 1fr 1fr;
}
@media (max-width: 576px) {
.forwhom-details-description-content[data-v-9ee22376] {
          display: block;
}
}
.forwhom-details-description-body[data-v-9ee22376] {
      padding-right: 36px;
}
@media (max-width: 576px) {
.forwhom-details-description-body[data-v-9ee22376] {
          width: 100%;
}
}
@media (max-width: 576px) {
.forwhom-details-description-list[data-v-9ee22376] {
        width: 100%;
}
}
.forwhom-details-description-list .item[data-v-9ee22376] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding-bottom: 26px;
}
.forwhom-details-description-list .number[data-v-9ee22376] {
      color: #f3594c;
      width: 50px;
      min-width: 50px;
      max-width: 50px;
      height: 50px;
      line-height: 40px;
      padding-left: 6px;
      border-radius: 50%;
      background-clip: padding-box;
      /* stops bg color from leaking outside the border: */
      text-align: center;
      font-size: 26px;
      border: 2px solid #f3594c;
      margin-right: 20px;
}
.forwhom-details-description-list .content-title[data-v-9ee22376] {
      font-weight: 900;
}
.forwhom-details-description-list .content p[data-v-9ee22376] {
      margin-bottom: 0;
}
.forwhom-details-form[data-v-9ee22376] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.forwhom-details-form > div[data-v-9ee22376] {
      -webkit-box-flex: 0;
          -ms-flex: 0 1 400px;
              flex: 0 1 400px;
}
.forwhom-details-button[data-v-9ee22376] {
    width: 100%;
    text-align: center;
}
.forwhom-details-button button[data-v-9ee22376] {
      display: inline-block;
      font-size: 19px;
      line-height: 1.2;
      font-weight: inherit;
      color: #000;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      background-color: transparent;
      border: 1px solid #000;
      border-radius: 0;
      padding: 10px 44px 12px 44px;
      background-color: #f3594c;
      color: #fff;
      border-color: #fff;
}
.forwhom-details-button button[data-v-9ee22376]:hover {
        color: #000;
        text-decoration: none;
}
.forwhom-details-button button[data-v-9ee22376]:focus, .forwhom-details-button button.focus[data-v-9ee22376] {
        outline: 0;
        color: #f3594c;
        background-color: #fff;
        border-color: #fff;
}
.forwhom-details-button button[data-v-9ee22376]:hover {
        background-color: #fff;
        color: #f3594c;
        border-color: #f3594c;
}
.forwhom-body[data-v-9ee22376] {
  position: relative;
  background-color: #495161;
  margin: 0 auto;
}
.forwhom-body-image[data-v-9ee22376] {
    max-width: 768px;
    height: 840px;
    margin: auto;
    padding-top: 60px;
    padding-bottom: 140px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.forwhom-body-image img[data-v-9ee22376] {
      height: 600px;
      margin: auto;
}
.forwhom-body-icon[data-v-9ee22376] {
    display: inline-block;
    width: 110px;
    position: absolute;
    text-align: center;
}
.forwhom-body-icon a[data-v-9ee22376]:hover {
      text-decoration: none;
}
.forwhom-body-icon-button[data-v-9ee22376] {
      margin: 0 -50px;
      display: inline;
      background-color: #f4bf29;
      color: white;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      padding: 2px 14px;
      white-space: nowrap;
}
.forwhom-body-icon[data-v-9ee22376]:hover {
      opacity: 0.5;
}
.forwhom-body-icons[data-v-9ee22376] {
    width: 600px;
    margin: auto;
    height: 100%;
    position: relative;
}
.forwhom-body-icons-overlay[data-v-9ee22376] {
      position: absolute;
      top: 0;
      height: 800px;
      width: 100%;
}
@media (max-width: 576px) {
.forwhom-body-icons-overlay[data-v-9ee22376] {
          display: none;
}
}
.forwhom-body-icons img[data-v-9ee22376] {
      width: 100%;
}
.forwhom-body-icons #company[data-v-9ee22376] {
      top: 240px;
      left: 50px;
}
.forwhom-body-icons #event[data-v-9ee22376] {
      top: 450px;
      left: 50px;
}
.forwhom-body-icons #one-on-one[data-v-9ee22376] {
      top: 600px;
      left: 200px;
}
.forwhom-body-icons #trade-organisation[data-v-9ee22376] {
      top: 450px;
      left: 440px;
}
.forwhom-body-icons #your-network[data-v-9ee22376] {
      top: 240px;
      left: 440px;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
button[data-v-1b212475] {
  display: inline-block;
  font-size: 19px;
  line-height: 1.2;
  font-weight: inherit;
  color: #000;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 0;
  padding: 10px 44px 12px 44px;
  border: 1px solid #f3594c;
  text-decoration: none;
  background: #f3594c;
  color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
}
button[data-v-1b212475]:hover {
    color: #000;
    text-decoration: none;
}
button[data-v-1b212475]:focus, button.focus[data-v-1b212475] {
    outline: 0;
    color: #f3594c;
    background-color: #fff;
    border-color: #fff;
}
@media (max-width: 576px) {
button[data-v-1b212475] {
      font-size: 14px;
      width: 100%;
      text-align: center;
      padding: 10px 14px 12px 14px;
}
}
button[data-v-1b212475]:disabled, button[data-v-1b212475]:disabled:hover {
    color: #f3594c;
    border-color: #f3594c;
    background: #fff;
    cursor: not-allowed;
}
button[data-v-1b212475]:hover, button[data-v-1b212475]:focus {
    background: #fff;
    color: #f3594c;
    border-color: #fff;
}
.section[data-v-1b212475] {
  margin-top: 20px;
}
.section h3[data-v-1b212475] {
    margin-bottom: 10px;
}
.section label[data-v-1b212475] {
    display: none;
    width: 260px;
    text-align: right;
    margin-right: 10px;
}
@media (max-width: 576px) {
.section label[data-v-1b212475] {
        width: auto;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 2px;
}
}
.section textarea[data-v-1b212475],
  .section input[data-v-1b212475] {
    font-size: 19px;
    padding: 0px 10px;
    border: 1px solid #d8d6cd;
}
@media (max-width: 576px) {
.section textarea[data-v-1b212475],
      .section input[data-v-1b212475] {
        font-size: 14px;
}
}
.section textarea[data-v-1b212475]:focus,
    .section input[data-v-1b212475]:focus {
      outline: none;
}
.section input[data-v-1b212475] {
    width: 100%;
    min-height: 50px;
}
.section input[data-v-1b212475]::-webkit-input-placeholder {
      color: #aca79e;
}
.section input[data-v-1b212475]::-moz-placeholder {
      color: #aca79e;
}
.section input[data-v-1b212475]:-ms-input-placeholder {
      color: #aca79e;
}
.section input[data-v-1b212475]::-ms-input-placeholder {
      color: #aca79e;
}
.section input[data-v-1b212475]::placeholder {
      color: #aca79e;
}
.button-row[data-v-1b212475] {
  margin-top: 26px;
  text-align: center;
}
.invalid[data-v-1b212475] {
  outline: 1px solid #f3594c;
}
.question[data-v-1b212475] {
  margin-top: 16px;
  width: 100%;
  height: 200px;
  resize: none;
  border-color: #edeae5;
}
.error[data-v-1b212475] {
  color: #f3594c;
}
.hidden[data-v-1b212475] {
  display: none !important;
}
.subscribeNewsletter[data-v-1b212475] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 14px;
  padding-left: 4px;
  margin-bottom: 19px;
}
.subscribeNewsletter input[data-v-1b212475] {
    width: auto;
    margin-bottom: 0;
}
label[for="subscribeNewsletter"][data-v-1b212475] {
  display: block;
  line-height: 1;
  padding: 0;
  padding-left: 26px;
  margin-bottom: 0;
}
.registration-confirm-title[data-v-1b212475] {
  color: #fff;
  font-size: 36px;
}
.registration-confirm-body[data-v-1b212475] {
  color: #414042;
  font-size: 19px;
  font-weight: 500;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.pilot[data-v-40ff0d50] {
  position: relative;
}
.pilot-header[data-v-40ff0d50] {
    width: 100%;
    height: 600px;
    margin-bottom: -200px;
    background-color: #495161;
    display: block;
}
.pilot-header img[data-v-40ff0d50] {
      height: 100%;
      width: 100%;
      -o-object-fit: contain;
         object-fit: contain;
}
@media (max-width: 576px) {
.pilot-header[data-v-40ff0d50] {
        display: none;
}
}
.pilot-header-mobile[data-v-40ff0d50] {
      display: none;
}
@media (max-width: 576px) {
.pilot-header-mobile[data-v-40ff0d50] {
          display: block;
          width: 100vw;
}
}
.pilot-header-mobile img[data-v-40ff0d50] {
        height: 100%;
        width: 100%;
        -o-object-fit: contain;
           object-fit: contain;
}
.pilot-body[data-v-40ff0d50] {
    position: relative;
    max-width: 992px !important;
    padding: 0 132px;
    background-color: #fff;
    margin: 0 auto;
    text-align: center;
}
@media (max-width: 576px) {
.pilot-body[data-v-40ff0d50] {
        max-width: 100vw;
        width: 100vw;
        padding: 0 14px;
}
}
.pilot-body-title[data-v-40ff0d50] {
      padding-top: 36px;
      line-height: 1;
      color: #f3594c;
      font-size: 44px;
      font-weight: 700;
}
@media (max-width: 576px) {
.pilot-body-title[data-v-40ff0d50] {
          line-height: 1.2;
}
}
.pilot-body-intro[data-v-40ff0d50] {
      margin-top: 26px;
      font-weight: 300;
      font-size: 26px;
      color: #414042;
      line-height: 1.3;
      padding: 26px 44px;
}
@media (max-width: 576px) {
.pilot-body-intro[data-v-40ff0d50] {
          padding: 14px 14px;
          font-size: 19px;
          line-height: 1.4;
}
}
.pilot-form[data-v-40ff0d50] {
    background-color: #d8d6cd;
    padding: 44px;
}
.pilot-form-columns[data-v-40ff0d50] {
      max-width: 768px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 44px;
}
@media (max-width: 576px) {
.pilot-form-columns[data-v-40ff0d50] {
          display: block;
}
}
.pilot-form-title[data-v-40ff0d50] {
      color: #414042;
      font-size: 36px;
}
.pilot-form-body[data-v-40ff0d50] {
      color: #414042;
      font-size: 19px;
      font-weight: 500;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.whats-app[data-v-5dbb7a64] {
  padding: 26px 0;
  background-color: #d8d6cd;
}
@media (max-width: 576px) {
.whats-app[data-v-5dbb7a64] {
      padding: 0;
      margin: 0;
}
}
.whats-app-content[data-v-5dbb7a64] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    max-width: 576px;
    margin: auto;
}
@media (max-width: 576px) {
.whats-app-content[data-v-5dbb7a64] {
        width: 90vw;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}
.whats-app-content .whats-app-title[data-v-5dbb7a64] {
          display: block;
          padding: 1em 0.5em;
}
.whats-app-content .whats-app-icon[data-v-5dbb7a64] {
          display: none;
}
.whats-app-content .whats-app-body[data-v-5dbb7a64] {
          padding: 1em 0.5em;
}
}
.whats-app-title[data-v-5dbb7a64] {
    color: #f3594c;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 40%;
            flex: 1 1 40%;
    font-size: 1.75em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
}
.whats-app-title a[data-v-5dbb7a64] {
      color: #f3594c;
      font-weight: bold;
}
.whats-app-icon[data-v-5dbb7a64] {
    line-height: 1em;
    font-size: 5em;
    padding: 0 0.5em;
}
.whats-app-icon a[data-v-5dbb7a64] {
      color: #2c3e50;
      font-weight: bold;
}
.whats-app-body[data-v-5dbb7a64] {
    color: #f3594c;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 40%;
            flex: 1 1 40%;
    font-size: 0.9em;
    line-height: 1.75em;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.body[data-v-0bdd09b7] {
  margin-bottom: 44px !important;
}
@media (max-width: 576px) {
.body[data-v-0bdd09b7] {
      width: 100vw;
}
}
.hero[data-v-0bdd09b7] {
  height: 480px;
}
@media (max-width: 576px) {
.hero[data-v-0bdd09b7] {
      display: none;
}
}
.hero-image[data-v-0bdd09b7] {
  width: 100%;
  height: 100%;
}
.hero-image img[data-v-0bdd09b7] {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}
.content-main[data-v-0bdd09b7] {
  position: relative;
  padding: 0 132px;
  top: -200px;
  background-color: #fff;
  margin-bottom: -200px;
}
@media (max-width: 576px) {
.content-main[data-v-0bdd09b7] {
      top: 0;
      margin-bottom: 0;
      padding: 26px;
}
}
.content-main .head[data-v-0bdd09b7] {
    margin: 14px 0;
}
.content-title[data-v-0bdd09b7] {
  line-height: 1;
  color: #f3594c;
  font-size: 44px;
  font-weight: 700;
}
@media (max-width: 576px) {
.content-title[data-v-0bdd09b7] {
      font-size: 36px;
}
}
.content-intro[data-v-0bdd09b7] {
  margin-top: 26px;
  font-weight: 300;
  font-size: 26px;
  color: #414042;
  line-height: 1.3;
}
@media (max-width: 576px) {
.content-intro[data-v-0bdd09b7] {
      font-size: 19px;
}
}
.content-body[data-v-0bdd09b7] {
  font-weight: 400;
  font-size: 19px;
}
@media (max-width: 576px) {
.content-body[data-v-0bdd09b7] {
      font-size: 14px;
}
}


/*# sourceMappingURL=base.a038b88e.css.map*/